<template>
  <v-row class="ma-3 justify-center">
    <div
      v-if="isLoadingProject"
      class="loader pt-12"
    >
      <v-progress-circular
        :size="70"
        color="#055289"
        indeterminate
      />
    </div>

    <v-card
      v-else-if="didLoadProjectFail"
      class="px-3 col-12 elevation-0"
    >
      <v-card-text class="error-message pa-3">
        <v-row>
          <v-icon>error_outline</v-icon>
        </v-row>

        <v-row class="col-12 header-2">
          {{ didLoadProjectFail.message || 'Error Unknown' }}
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-if="!project"
      class="px-3 col-12 elevation-0"
    >
      <v-card-text class="error-message pa-3">
        <v-row>
          <v-icon>error_outline</v-icon>
        </v-row>

        <v-row class="col-12 header-2">
          Not Found
        </v-row>
      </v-card-text>
    </v-card>

    <template v-else>
      <v-col class="col-9">
        <v-card
          class="px-3 pt-3 pb-6 elevation-0"
        >
          <v-card-title class="darkblue--text">
            {{ project.projectName }}
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    ID
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ project.projectId }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Code
                  </v-col>
                </v-row>
                <v-row class="body-2 mt-0">
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ project.projectCode }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="px-3 pt-3 pb-6 elevation-0">
          <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
            Profile
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col>
                <v-row class="subtitle-2">
                  <v-col class="midgray--text">
                    Status
                  </v-col>
                </v-row>

                <v-row
                  :class="`body-2 mt-0 ${isActiveColor}--text`"
                >
                  <v-col class="py-0 font-weight-medium nearblack--text">
                    {{ isActiveLabel }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
          class="px-3 pt-3 pb-6 elevation-0"
        >
          <v-card-subtitle class="font-weight-bold midgray--text text-uppercase">
            Change Log
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col>
                <v-row class="body-2">
                  <v-col class="py-0 font-weight-medium">
                    <span class="pr-1 midgray--text">
                      Created on
                    </span>

                    <span class="nearblack--text">
                      {{ createdOn }}
                    </span>

                    <span class="px-1 midgray--text">by</span>

                    <span class="nearblack--text">
                      {{ createdBy }}
                    </span>
                  </v-col>
                </v-row>

                <v-row
                  v-if="updatedBy"
                  class="body-2"
                >
                  <v-col class="py-0 font-weight-medium">
                    <span class="pr-1 midgray--text">Updated on</span>

                    <span class="nearblack--text">
                      {{ updatedOn }}
                    </span>

                    <span class="px-1 midgray--text">by</span>

                    <span class="nearblack--text">
                      {{ updatedBy }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import dates from 'src/utils/dates'

const { mapActions, mapState } = createNamespacedHelpers('internal')
const { mapState: mapLayoutState } = createNamespacedHelpers('layout')
const { mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Project',
  data() {
    return {
      colorMap: {
        false: 'toasted',
        true: 'midgray',
        'n/a': 'midgray',
        undefined: 'midgray',
      },
    }
  },
  computed: {
    ...mapLayoutState({
      icons: (state) => state.icons,
      valueIcons: (state) => state.icons.values,
    }),
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
    ...mapState({
      didLoadProjectFail: (state) => state.didLoadProjectFail,
      isLoadingProject: (state) => state.isLoadingProject,
      project: (state) => state.project,
    }),
    isDeleted() {
      const { project = {} } = this
      const { isDeleted } = project

      return isDeleted
    },
    isActive() {
      const { project = {} } = this
      const { isActive } = project

      return isActive
    },
    isActiveColor() {
      const { isActive, valueIcons } = this
      const status = String(isActive)
      const { color = 'nearblack' } = valueIcons[status] || {}

      return color
    },
    isActiveLabel() {
      if (this.isDeleted) return 'Deleted'
      return this.isActive ? 'Active' : 'Inactive'
    },
    createdBy() {
      const { project = {} } = this
      const unknownActor = 'Unknown'
      const { assoc_email = unknownActor, updated_dt } = project

      return !updated_dt ? assoc_email : unknownActor
    },
    createdOn() {
      const { project = {} } = this
      const { created_dt } = project

      return dates.getFormattedLocalDateTime(created_dt)
    },
    updatedBy() {
      const { project = {} } = this
      const { assoc_email } = project

      return assoc_email
    },
    updatedOn() {
      const { project = {} } = this
      const { updated_dt } = project

      return dates.getFormattedLocalDateTime(updated_dt)
    },
  },
  watch: {
    reportId(newValue) {
      if (!newValue) return null

      return this.getProject(newValue)
    },
  },
  methods: {
    ...mapActions(['getProject']),
  },
}
</script>

<style lang="scss" scoped>
.v-card__subtitle {
  letter-spacing: 0.025rem;
}
</style>
